<template>
    <form class="p-4" @submit="onLoginClick">
        <b-container>
            <b-row>
                <b-col>
                    <div class="text-center">
                        <img style="height: 4.25rem; width: 20rem" src="SmartRacks.svg" class="rounded mb-2">
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="login-header">
                            <div class="mb-2 title">{{ title.replace('FM', '') }}</div>
                            <div class="mt-2">Sign In to your account</div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <dx-form
                            ref="form"
                            :form-data.sync="formData"
                            :show-validation-summary="true"
                    >
                        <dx-item
                                data-field="Login"
                                editor-type="dxTextBox"
                                :editor-options="{ placeholder: 'Login (E-Mail or Phone)', width: '100%', height: 35 }"
                        >
                            <dx-required-rule message="Login is required"/>
                            <dx-label :visible="false"/>
                        </dx-item>
                        <dx-empty-item/>
                        <dx-item
                                data-field="Password"
                        >
                            <dx-required-rule message="Password is required"/>
                            <dx-label :visible="false"/>

                            <template #default>
                                <dx-text-box
                                        id="password"
                                        ref="PasswordTextBoxRef"
                                        placeholder="Password"
                                        width="100%"
                                        :input-attr="{ autocomplete: 'new-user' }"
                                        :height="35"
                                        :mode.sync="passwordMode"
                                        :value.sync="formData.Password"
                                        :disabled.sync="validating"
                                        @focus-in="onFocusIn"
                                >
                                    <DxTextBoxButton
                                            :options="passwordButton"
                                            name="password"
                                            location="after"
                                    />
                                </dx-text-box>
                            </template>
                        </dx-item>
                        <dx-empty-item/>
                        <dx-button-item>
                            <dx-button-options
                                    width="100%"
                                    type="default"
                                    template="login"
                                    :use-submit-behavior="true"
                                    :height="35"
                            >
                            </dx-button-options>
                        </dx-button-item>

                        <template #login>
                            <div>
                            <span class="dx-button-text">
                              <span style="font-size: 1.5em">Log in</span>
                            </span>
                            </div>
                        </template>
                    </dx-form>
                    <div v-if="error" class="dx-field pt-2">
                        <div class="link text-center mt-2 font-weight-bold text-danger">
                            {{ error.message }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="link text-center mt-2">
                            <router-link to="/user/password/forgot">Forgot Password?</router-link>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="link text-center mt-2">
                            By clicking on the "Log In" button, you verify that you have<br>reviewed and agreed to the FM Fuel & Resources<br><a @click.prevent="openPrivacyNotice" href="">Privacy Notice</a>.
                        </div>
                    </div>
                    <hr>

                    <div class="dx-field">
                        <div class="link text-left mt-2">
                            <a @click.prevent="ClearCache" href="">Clear Cache</a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </form>
</template>

<script>
/* eslint-disable */

import DxForm, {
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxCustomRule,
    DxRequiredRule,
    DxEmptyItem
} from 'devextreme-vue/form';

import {DxTextBox, DxButton as DxTextBoxButton} from 'devextreme-vue/text-box';
import {confirm} from "devextreme/ui/dialog";

export default {
    name: 'login-view',

    components: {
        DxForm,
        DxItem,
        DxEmptyItem,
        DxLabel,
        DxButtonItem,
        DxButtonOptions,
        DxRequiredRule,
        DxCustomRule,
        DxTextBoxButton,
        DxTextBox
    },

    data() {
        return {
            environment: process.env,

            error: null,
            title: this.$appInfo.title,
            company: this.$appInfo.company,
            formData: {
                Login: "",
                Password: "",
                DeviceName: "vue-app",
            },

            validating: false,
            passwordMode: 'password',
            passwordButton: {
                icon: 'eye.png',
                type: 'default',

                onClick: (e) => {
                    this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
                    //this.PasswordTextBox.focus()
                }
            },
        }
    },

    mounted() {
        this.formData.Login = this.User ? this.User.Email ? this.User.Email : this.User.Phone : ""
    },

    methods: {
        async onLoginClick(e) {
            e.preventDefault()

            await Promise.all([this.Login()])
        },

        async Login() {
            this.validating = true

            await this.$store.dispatch('user/login', this.formData)
                    .then((r) => {
                        this.validating = false


                        this.$store.dispatch('customers/GET_CUSTOMERS').then(() => {
                            this.$gates.setPermissions(this.$store.getters["user/permissions"])
                            this.$gates.setRoles(this.$store.getters["user/roles"])
                            this.$root.$emit('user-login');

                            this.$store.dispatch('bidtemplates/GET_BIDTEMPLATES', this.Customer).then(() => {
                                this.$router.push('/home')
                            })
                        })
                    })
                    .catch(e => {
                        this.error = e
                        this.validating = false
                    }).finally(() => {
                        this.validating = false
                    })
        },

        async ClearCache() {
            let result = confirm("Are you sure?", `Clear Cache`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    localStorage.clear();
                    location.reload();
                }
            })
        },

        onFocusIn(e) {
            setTimeout(() => {
                let el = e.component._$element

                el.find(".dx-texteditor-input")[0].selectionStart = this.formData.Password.length;
                el.find(".dx-texteditor-input")[0].selectionEnd = this.formData.Password.length;
            }, 0);
        },

        openPrivacyNotice(e) {
            window.open('/legal/privacy-notice.htm', "_blank")
        }
    },

    computed: {
        AuthStatus() {
            return this.$store.getters["user/status"];
        },

        User() {
            return this.$store.getters['user/user']
        },

        Customer() {
            return this.$store.getters['customers/customer']
        },

        PasswordTextBox() {
            return this.$refs.PasswordTextBoxRef.instance
        }
    }
}
</script>

<style lang="scss">
@import "../../themes/generated/variables.base";
@import "../../fm-styles";

.login-header {
    text-align: center;
    margin-bottom: 20px;

    .title {
        color: $FuelMastersBlue;
        font-weight: bold;
        font-size: 20px;
        margin: 0;
    }

    .amp {
        color: $FuelMastersRed;
        font-weight: bold;
        font-size: 24px;
        margin: 0;
    }
}

</style>
